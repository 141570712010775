import React from "react";

import Timer from "./Timer";
import config from "./config";

function WorkoutSelect() {
  const [workout, setWorkout] = React.useState("");

  const steps = config[workout] || [];

  return (
    <div className="flex justify-center h-full">
      <div className="flex flex-col items-center">
        <div className="flex flex-row items-center">
          {Object.entries(config).map(([workout, ...rest]) => (
            <button
              key={workout}
              className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 mx-2 rounded"
              onClick={() => setWorkout(workout)}
            >
              {workout}
            </button>
          ))}
        </div>

        <div className="text-4xl">{workout}</div>

        {steps.length > 0 && <Timer steps={steps} countdown={3} />}
      </div>
    </div>
  );
}

export default WorkoutSelect;
