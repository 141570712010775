interface Step {
  duration: number;
  name: string;
}

const config: { [name: string]: Step[] } = {
  "HIIT": [
    {
      duration: 5,
      name: "",
    },
    {
      duration: 30,
      name: "Squatty Potty",
    },
    {
      duration: 30,
      name: "Rest",
    },
    {
      duration: 30,
      name: "Woopty Woop",
    },
    {
      duration: 30,
      name: "Rest",
    },
    {
      duration: 15,
      name: "Leggy Weggy",
    },
    {
      duration: 15,
      name: "Halfway",
    },
    {
      duration: 30,
      name: "Rest",
    },
    {
      duration: 30,
      name: "High Stepping",
    },
    {
      duration: 30,
      name: "Rest",
    },
    {
      duration: 30,
      name: "Weird ones",
    },
    {
      duration: 30,
      name: "Rest",
    },
    {
      duration: 0,
      name: "Completed",
    },
  ],
  "Short Rest HIIT": [
    {
      duration: 5,
      name: "",
    },
    {
      duration: 30,
      name: "Squatty Potty",
    },
    {
      duration: 15,
      name: "Rest",
    },
    {
      duration: 30,
      name: "Woop tee Woop",
    },
    {
      duration: 15,
      name: "Rest",
    },
    {
      duration: 15,
      name: "Leggy Weggy",
    },
    {
      duration: 15,
      name: "Halfway",
    },
    {
      duration: 15,
      name: "Rest",
    },
    {
      duration: 30,
      name: "High Stepping",
    },
    {
      duration: 15,
      name: "Rest",
    },
    {
      duration: 30,
      name: "Weird ones",
    },
    {
      duration: 15,
      name: "Rest",
    },
    {
      duration: 0,
      name: "Completed",
    },
  ],
  "Crazy HIIT Circuit 1": [
    {
      duration: 5,
      name: "",
    },
    {
      duration: 30,
      name: "Star Jumps",
    },
    {
      duration: 30,
      name: "Fast Running",
    },
    {
      duration: 30,
      name: "High Knees",
    },
    {
      duration: 30,
      name: "Power Jacks",
    },
    {
      duration: 30,
      name: "Toe Taps",
    },
    {
      duration: 45,
      name: "Rest",
    },
    {
      duration: 0,
      name: "Completed",
    },
  ],
  "Crazy HIIT Circuit 2": [
    {
      duration: 5,
      name: "",
    },
    {
      duration: 30,
      name: "Star Jumps",
    },
    {
      duration: 30,
      name: "Walk outs",
    },
    {
      duration: 30,
      name: "Squats",
    },
    {
      duration: 30,
      name: "Jumping Lunges",
    },
    {
      duration: 30,
      name: "London Bridges",
    },
    {
      duration: 45,
      name: "Rest",
    },
    {
      duration: 0,
      name: "Completed",
    },
  ],
  "Cardio HIIT": [
    {
      duration: 30,
      name: "Jumping Jacks",
    },
    {
      duration: 20,
      name: "Jump Lunge Squats",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Jump Lunge Squats",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Jump Lunge Squats",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Jump Lunge Squats",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Burpee Jumps",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Burpee Jumps",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Burpee Jumps",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Burpee Jumps",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Spiderman Pushups",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Spiderman Pushups",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Spiderman Pushups",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Spiderman Pushups",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Jumping Jack Torso Twist",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Jumping Jack Torso Twist",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Jumping Jack Torso Twist",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Jumping Jack Torso Twist",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Bear Crawl",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Bear Crawl",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Bear Crawl",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Bear Crawl",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Speed Skater Lunge",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Speed Skater Lunge",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Speed Skater Lunge",
    },
    {
      duration: 10,
      name: "Rest",
    },
    {
      duration: 20,
      name: "Speed Skater Lunge",
    },
    {
      duration: 10,
      name: "Rest",
    },
  ],
};

export default config;
