import React from "react";

interface TimerProps {
  steps: {
    duration: number;
    name: string;
  }[];
  countdown?: number;
}

function Timer({ steps, countdown }: TimerProps) {
  const [step, setStep] = React.useState(0);
  const [time, setTime] = React.useState(0);
  const [isRunning, setIsRunning] = React.useState(false);

  const currentStep = steps[step] || {};

  const start = () => {
    setIsRunning(true);
  };

  const stop = React.useCallback(() => {
    setIsRunning(false);
  }, []);

  const reset = React.useCallback(() => {
    stop();
    setTime(0);
    setStep(0);
  }, [stop]);

  const speak = (phrase: string) => {
    const utterance = new SpeechSynthesisUtterance(phrase);
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utterance);
  };

  // Progress time when running
  React.useEffect(() => {
    let interval: number;

    if (isRunning) {
      interval = window.setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  // Side effects as a result of time progressing
  React.useEffect(() => {
    const startStep = (i: number) => {
      setStep(i);
      setTime(0);
      speak(steps[i].name);
    };

    const timeLeft = steps[step].duration - time;

    if (countdown && timeLeft <= countdown && timeLeft > 0) {
      speak(String(timeLeft));
    }

    if (timeLeft <= 0) {
      if (steps.length > step + 1) {
        startStep(step + 1);
      } else {
        stop();
      }
    }
  }, [currentStep.duration, time, step, steps, countdown, stop]);

  // Reset when the steps change
  React.useEffect(() => {
    reset();
  }, [steps, reset]);

  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col items-center">
        <div className="text-4xl">{currentStep.name}</div>

        <div className="text-6xl">{currentStep.duration - time}</div>
        <div className="flex flex-row mt-4">
          {!isRunning && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-2 rounded"
              onClick={start}
            >
              Start
            </button>
          )}
          {isRunning && (
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mx-2 rounded"
              onClick={stop}
            >
              Pause
            </button>
          )}

          <button
            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 mx-2 rounded"
            onClick={reset}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default Timer;
