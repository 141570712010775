import React from "react";

import WorkoutSelect from "./WorkoutSelect";
import "./styles/tailwind.css";

function App() {
  return (
    <div className="container mx-auto h-screen">
      <WorkoutSelect />
    </div>
  );
}

export default App;
